import Vue            from 'vue'
import Router         from 'vue-router'

Vue.use(Router)

export const routerMap = [
    {
        path     : '/',
        redirect : '/dashboard',
        hidden   : true,
    },{
        path: '/auth',
        name : 'auth_by_hash',
        component: () => import('@/views/AuthByHash'),
        meta: {},
        hidden   : true,
    },
]

export const routerComponents = [
    
    {
        name : 'dashboard',
        component: () => import('@/views/Dashboard'),
    },{
        name : 'site_pages',
        component: () => import('@/views/Site/Pages'),
    },{
        name : 'site_articles',
        component: () => import('@/views/Site/Articles'),
    },{
        name : 'site_landings',
        component: () => import('@/views/Site/Landings'),
    },{
        name : 'site_promosliders',
        component: () => import('@/views/Site/Landings/Slides'),
    },{
        name : 'site_promosliders_item',
        component: () => import('@/views/Site/Landings/Slides/SetPage'),
    },{
        name : 'site_promobanners',
        component: () => import('@/views/Site/PromoBanners'),
    },{
        name : 'site_news',
        component: () => import('@/views/Site/News'),
    },{
        name : 'site_blog',
        component: () => import('@/views/Site/Blog'),
    },{
        name : 'site_solutions',
        component: () => import('@/views/Site/Solutions'),
    },{
        name : 'site_banners',
        component: () => import('@/views/Site/Banners'),
    },{
        name : 'about_blocks',
        component: () => import('@/views/Site/About/Blocks'),
    },{
        name : 'about_laws',
        component: () => import('@/views/Site/About/Laws'),
    },{
        name : 'site_guarantees',
        component: () => import('@/views/Site/Garantees'),
    },{
        name : 'faq_categories',
        component: () => import('@/views/Site/FAQ/Category'),
    },{
        name : 'faq_questions',
        component: () => import('@/views/Site/FAQ/Question'),
    },{
        name : 'site_tags',
        component: () => import('@/views/Site/Tags'),
    },{
        name : 'site_categories',
        component: () => import('@/views/Site/Categories'),
    },{
        name : 'site_contacts',
        component: () => import('@/views/Site/Contacts'),
    },{
        name : 'site_texts',
        component: () => import('@/views/Site/TemplateTexts'),
    },
    
    {
        name : 'catalog_category',
        component: () => import('@/views/Catalog/Category'),
    },{
        name : 'catalog_series',
        component: () => import('@/views/Catalog/Series'),
    },{
        name : 'catalog_products',
        component: () => import('@/views/Pim/Product'),
    },{
        name : 'catalog_products_item',
        component: () => import('@/views/Pim/Product/ProductPage'),
    },{
        name : 'catalog_market_launch',
        component: () => import('@/views/Pim/MarketLaunch'),
    },{
        name : 'pim_addproducts',
        component: () => import('@/views/Pim/NewProducts'),
    },{
        name : 'pim_addproducts_item',
        component: () => import('@/views/Pim/NewProducts'),
    },{
        name : 'pim_hierarchy',
        component: () => import('@/views/Pim/Ierarchy'),
    },{
        name : 'pim_presets',
        component: () => import('@/views/Pim/Presets'),
    },{
        name : 'pim_comments',
        component: () => import('@/views/Pim/Comments'),
    },{
        name : 'pim_warehouse',
        component: () => import('@/views/Pim/Warehouse'),
    },{
        name : 'pim_emulate_1c',
        component: () => import('@/views/Pim/Emulate1C'),
        testonly: true,
    },{
        name : 'catalog_pictogram',
        component: () => import('@/views/Catalog/Pictograms'),
    },{
        name : 'catalog_application_images',
        component: () => import('@/views/Catalog/ApplicationImages'),
    },{
        name : 'catalog_icons',
        component: () => import('@/views/Catalog/ProductIcons'),
    },{
        name : 'catalog_parameters',
        component: () => import('@/views/Catalog/ProductParameters'),
    },{
        name : 'catalog_filters',
        component: () => import('@/views/Catalog/CategoryFilters'),
    },{
        name : 'catalog_tags',
        component: () => import('@/views/Catalog/Tags'),
    },{
        name : 'catalog_garantee',
        component: () => import('@/views/Catalog/Garantees'),
    },{
        name : 'catalog_texts',
        component: () => import('@/views/Catalog/Texts'),
    },{
        name : 'parking_components',
        component: () => import('@/views/Parking/Components'),
    },{
        name : 'parking_parameters',
        component: () => import('@/views/Parking/Parameters'),
    },{
        name : 'pricelists_pricelists',
        component: () => import('@/views/Pricelists/DynamicPricelists'),
    },{
        name : 'pricelists_pricelists_item',
        component: () => import('@/views/Pricelists/DynamicPricelists/PricelistPage'),
    },{
        name : 'pricelists_files',
        component: () => import('@/views/Support/Other/Pricelists'),
    },{
        name : 'kp_settings',
        component: () => import('@/views/Kp/KpParkingSettings'),
    },{
        name : 'kp_check',
        component: () => import('@/views/Kp/KpParkingCheck'),
    },{
        name : 'kp_parking',
        component: () => import('@/views/Kp/KpParking'),
    },{
        name : 'projects_projects',
        component: () => import('@/views/Projects/Projects'),
    },{
        name : 'projects_projects_item',
        component: () => import('@/views/Projects/Projects/ProjectPage'),
    },{
        name : 'projects_types',
        component: () => import('@/views/Projects/Types'),
    },{
        name : 'projects_markets',
        component: () => import('@/views/Projects/Markets'),
    },{
        name : 'projects_zones',
        component: () => import('@/views/Projects/Zones'),
    },{
        name : 'dealers_dealers_item',
        component: () => import('@/views/Dealers/Dealers/DealerPage'),
    },{
        name : 'dealers_dealers',
        component: () => import('@/views/Dealers/Dealers'),
    },{
        name : 'dealers_new_dealers',
        component: () => import('@/views/Dealers/NewDealers'),
    },{
        name : 'dealers_types',
        component: () => import('@/views/Dealers/Types'),
    },{
        name : 'dealers_services',
        component: () => import('@/views/Dealers/Services'),
    },{
        name : 'dealers_expanded_services',
        component: () => import('@/views/Dealers/ExpandedServices'),
    },{
        name : 'dealers_payment_types',
        component: () => import('@/views/Dealers/PaymentTypes'),
    },{
        name : 'dealers_cities',
        component: () => import('@/views/Dealers/Cities'),
    },{
        name : 'dealers_regions',
        component: () => import('@/views/Dealers/Regions'),
    },{
        name : 'support_product_passports',
        component: () => import('@/views/Support/Product/Passports'),
    },{
        name : 'support_product_instructions',
        component: () => import('@/views/Support/Product/Instructions'),
    },{
        name : 'support_product_certificates',
        component: () => import('@/views/Support/Product/Certificates'),
    },{
        name : 'support_product_catalogs',
        component: () => import('@/views/Support/Product/Catalogs'),
    },{
        name : 'support_product_projectdocs',
        component: () => import('@/views/Support/Product/Projectdocs'),
    },{
        name : 'support_product_presentations',
        component: () => import('@/views/Support/Product/Presentations'),
    },{
        name : 'support_product_video',
        component: () => import('@/views/Support/Product/Video'),
    },{
        name : 'support_other_company_certificates',
        component: () => import('@/views/Support/Other/CompanyCertificates'),
    },{
        name : 'support_other_nocatalog_certificates',
        component: () => import('@/views/Support/Other/NoProductCertificates'),
    },{
        name : 'support_other_files',
        component: () => import('@/views/Support/Other/SiteFiles'),
    },

    {
        name : 'bpt_category',
        component: () => import('@/views/Bpt/Category'),
    },{
        name : 'bpt_files',
        component: () => import('@/views/Bpt/Files'),
    },{
        name : 'bpt_video',
        component: () => import('@/views/Bpt/Video'),
    },

    {
        name : 'shop_users',
        component: () => import('@/views/Shop/Users'),
    },{
        name : 'shop_orders',
        component: () => import('@/views/Shop/Orders'),
    },{
        name : 'feedback_subscribe',
        component: () => import('@/views/Feedback/Subscribe'),
    },{
        name : 'request_category',
        component: () => import('@/views/Feedback/RequestCategory'),
    },{
        name : 'request',
        component: () => import('@/views/Feedback/Request'),
    },{
        name : 'feedback_project',
        component: () => import('@/views/Feedback/Project'),
    },{
        name : 'lk_request',
        component: () => import('@/views/Feedback/LkRequest'),
    },

    {
        name : 'academy_city',
        component: () => import('@/views/Academy/City'),
    },{
        name : 'academy_trainer',
        component: () => import('@/views/Academy/Trainer'),
    },{
        name : 'academy_category',
        component: () => import('@/views/Academy/Category'),
    },{
        name : 'academy_courses',
        component: () => import('@/views/Academy/Courses'),
    },{
        name : 'academy_courses_online',
        component: () => import('@/views/Academy/CoursesOnline'),
    },{
        name : 'academy_events',
        component: () => import('@/views/Academy/Events'),
    },{
        name : 'academy_certificate',
        component: () => import('@/views/Academy/Certificates'),
    },{
        name : 'academy_registrations',
        component: () => import('@/views/Academy/Registrations'),
    },{
        name : 'academy_questions',
        component: () => import('@/views/Academy/Questions'),
    },{
        name : 'academy_information',
        component: () => import('@/views/Academy/Information'),
    },{
        name : 'academy_ispring_users',
        component: () => import('@/views/Academy/IspringUsers'),
    },

    {
        name : 'cameclub_events',
        component: () => import('@/views/Came Club/Events'),
    },{
        name : 'cameclub_cities',
        component: () => import('@/views/Came Club/Cities'),
    },{
        name : 'cameclub_places',
        component: () => import('@/views/Came Club/Places'),
    },{
        name : 'cameclub_teams',
        component: () => import('@/views/Came Club/Teams'),
    },

    {
        name : 'seo_settings',
        component: () => import('@/views/Seo/Settings'),
    },{
        name : 'seo_redirects',
        component: () => import('@/views/Seo/Redirects'),
    },{
        name : 'seo_pages',
        component: () => import('@/views/Seo/Records/Pages'),
    },{
        name : 'seo_landings',
        component: () => import('@/views/Seo/Records/Landings'),
    },{
        name : 'seo_articles',
        component: () => import('@/views/Seo/Records/Articles'),
    },{
        name : 'seo_news',
        component: () => import('@/views/Seo/Records/News'),
    },{
        name : 'seo_blog',
        component: () => import('@/views/Seo/Records/Blog'),
    },{
        name : 'seo_catalog_category',
        component: () => import('@/views/Seo/Records/CatalogCategory'),
    },{
        name : 'seo_catalog_products',
        component: () => import('@/views/Seo/Records/CatalogProduct'),
    },{
        name : 'seo_catalog_tags',
        component: () => import('@/views/Seo/Records/CatalogTags'),
    },{
        name : 'seo_pricelists',
        component: () => import('@/views/Seo/Records/Pricelists'),
    },{
        name : 'seo_projects',
        component: () => import('@/views/Seo/Records/Projects'),
    },{
        name : 'seo_dealers_cities',
        component: () => import('@/views/Seo/Records/DealerCity'),
    },{
        name : 'seo_academy_city',
        component: () => import('@/views/Seo/Records/AcademyCity'),
    },{
        name : 'seo_academy_courses',
        component: () => import('@/views/Seo/Records/AcademyCourses'),
    },{
        name : 'seo_academy_trainer',
        component: () => import('@/views/Seo/Records/AcademyTrainers'),
    },{
        name : 'seo_academy_online_courses',
        component: () => import('@/views/Seo/Records/AcademyOnlineCourses'),
    },

    {
        name : 'site_settings',
        component: () => import('@/views/Administration/Settings'),
    },

    {
        name : 'app_config',
        component: () => import('@/views/Administration/AppConfig'),
    },

    {
        name : 'admin_menu',
        component: () => import('@/views/Administration/PimMenu'),
    },{
        name : 'admin_users',
        component: () => import('@/views/Administration/Users'),
    },{
        name : 'pim_moderators',
        component: () => import('@/views/Administration/PimModerators'),
    },{
        name : 'pim_attributes',
        component: () => import('@/views/Administration/PimAttributes'),
    },{
        name : 'dealers_attributes',
        component: () => import('@/views/Administration/DealersAttributes'),
    },{
        name : 'projects_attributes',
        component: () => import('@/views/Administration/ProjectsAttributes'),
    },{
        name : 'emailsettings',
        component: () => import('@/views/Administration/EmailSettings'),
    },{
        name : 'administration_dealers_regions',
        component: () => import('@/views/Administration/DealersRegions'),
    },{
        name : 'oxilor_cities',
        component: () => import('@/views/OxilorCities'),
    },{
        name : 'filemanager',
        component: () => import('@/views/Administration/FileManager'),
    },{
        name : 'filemanager_folder',
        component: () => import('@/views/Administration/FileManager'),
    },

    {
        name : 'restusers',
        component: () => import('@/views/Administration/RestUsers'),
    },{
        name : 'restlog',
        component: () => import('@/views/Administration/RestLog'),
    },

    {
        name : 'amousers',
        component: () => import('@/views/Administration/AmoUsers'),
    },{
        name : 'amo_sendlog',
        component: () => import('@/views/Administration/AmoGetLog'),
    },{
        name : 'amo_getlog',
        component: () => import('@/views/Administration/AmoSendLog'),
    },{
        name : 'amo_emulate',
        component: () => import('@/views/Administration/AmoEmulate'),
        testonly: true,
    },



    {
        name : 'docs_pages',
        component: () => import('@/views/Documentation/Pages'),
    },{
        name : 'docs_contacts',
        component: () => import('@/views/Documentation/Contacts'),
    },{
        name : 'docs_dealers',
        component: () => import('@/views/Documentation/Dealers'),
    },{
        name : 'docs_kp_parking',
        component: () => import('@/views/Documentation/Parking_Kp'),
    },{
        name : 'docs_pricing',
        component: () => import('@/views/Documentation/Pricing'),
    },
    
    {
        name : 'changelog',
        component: () => import('@/views/Changelog'),
    },
    
    {
        name : 'projects_sale_objects',
        component: () => import('@/views/ProjectsSale/Objects'),
    },{
        name : 'projects_sale_projects',
        component: () => import('@/views/ProjectsSale/Projects'),
    },

    {
        name : 'reporting_pim',
        component: () => import('@/views/Reporting/Pim'),
    },
]

export default new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: routerMap,
    mode: 'history'
})
