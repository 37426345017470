import Vue  from 'vue'
import Urls from '@/../config/url.js'

const state = {
    list         : [],
    current      : {},
    data_loading : false,
    total_count  : 0,
    edit_window  : false,
    attributes   : [],
    options_list : [],
}

const module_name = 'adminAttributesPim'
const url = Urls.adminAttributesPim

const actions = {
    get_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_list', props, { root: true })
    },

    get_options({commit, dispatch, rootState}){
        let props = {
            url     : url,
            name    : module_name,
            query_params : {
                fields: ['field','name','xls'],
                find: {'editors': true},
            },
            option_title: 'name',
            add_fields: ['xls']
        }

        return dispatch('app/get_options', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_fields({dispatch, commit, rootState}, type){
        let params = {find: {}}
        params.find[type] = true

        let query_params = {
            where: JSON.stringify(params)
        }

        return new Promise((resolve, reject) => {
            Vue.axios.get(url, {params: query_params})
            .then((response) => {
                resolve(response);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_system_attributes({commit}){
        let array = [
            {'field': "code",                    'name': "Артикул",},
            {'field': 'name',                    'name': 'Имя товара'},
            {'field': 'url',                     'name': 'URL товара'},
            {'field': 'product_type',            'name': 'Тип продукции'},
            {'field': 'category_id',             'name': 'Категория'},
            {'field': 'serie_id',                'name': 'Модельный ряд'},
            {'field': 'site_show',               'name': 'Отображать на сайте'},
            {'field': 'order_available',         'name': 'Доступно для заказа'},
            {'field': 'catalog_show',            'name': 'Отображать в каталоге'},
            {'field': 'price_dealer',            'name': 'Цена дилер'},
            {'field': 'price_firm',              'name': 'Цена торговый дом'},
            {'field': 'price_distributor',       'name': 'Цена дистрибьютор'},
            {'field': 'price',                   'name': 'Цена розничная'},
            {'field': 'price_wo_discount',       'name': 'Цена до скидки',},
            {'field': 'future_prices',           'name': 'Будущие цены',},
            {'field': 'analog_product',          'name': 'Артикул аналога',},
            {'field': 'popular',                 'name': 'Популярный',},
            {'field': 'sortn',                   'name': 'Сортировка в выдаче',},
            {'field': 'promo',                   'name': 'Промо',},
            {'field': 'promo_priority',          'name': 'Промо приоритет',},
            {'field': 'no_pricelist',            'name': 'Не показывать в прайсах',},
            {'field': 'pricelist_sortn',         'name': 'Сортировка в прайсах',},
            {'field': 'pricelists',              'name': 'Используется в прайс-листах',},
            {'field': 'full_name',               'name': 'Наименование',},
            {'field': 'short_name',              'name': 'Краткое наименование',},
            {'field': 'seo_tags',                'name': 'SEO теги',},
            {'field': 'key_possibility',         'name': 'Ключевая возможность',},
            {'field': 'video',                   'name': 'Видео',},
            {'field': 'commercial_description',  'name': 'Коммерческое описание',},
            {'field': 'id_1s',                   'name': 'Идентификатор 1С'},
            {'field': 'print_name',              'name': 'Наименование для печати'},
            {'field': 'it_clf',                  'name': 'Итальянская классификация товара'},
            {'field': 'log_unit',                'name': 'Единица измерения'},
            {'field': 'log_pack',                'name': 'Кратность упаковки'},
            {'field': 'log_weight',              'name': 'Вес (кг)'},
            {'field': 'log_width',               'name': 'Ширина (мм)'},
            {'field': 'log_height',              'name': 'Высота (мм)'},
            {'field': 'log_depth',               'name': 'Глубина (мм)'},
            {'field': 'main_image',              'name': 'Основное изображение',},
            {'field': 'application_image',       'name': 'Изображение применения',},
            {'field': 'drawing_images',          'name': 'Чертежи',},
            {'field': 'icons',                   'name': 'Иконки',},
            {'field': 'features',                'name': 'Особенности и преимущества',},
            {'field': 'funcs',                   'name': 'Функционал',},
            {'field': 'parameters',              'name': 'Технические Характеристики',},
            {'field': 'limits',                  'name': 'Ограничения',},
            {'field': 'instructions_file',       'name': 'Инструкция',},
            {'field': 'passports_file',          'name': 'Паспорт автоматической системы',},
            {'field': 'catalogs_file',           'name': 'Каталог',},
            // {'field': 'install_file',            'name': 'Инструкция по установке',},
            {'field': 'project_file',            'name': 'Проектная документация',},
            {'field': 'certificate_file',        'name': 'Сертификаты',},
            {'field': 'accessories',             'name': 'Аксессуары',},
            {'field': 'accessory_for',           'name': 'Является аксессуаром к',},
            // {'field': 'required_accessories',    'name': 'Обязательные аксессуары',},
            // {'field': 'req_accessory_for',       'name': 'Является обязательным аксессуаром к',},
            {'field': 'complect_content',        'name': 'Является комплектом и включает в себя',},
            {'field': 'complects_with_product',  'name': 'Входит в комплекты',},
            {'field': 'material',       'name': 'Материалы',},
            {'field': 'presets',       'name': 'Пресеты',},
            {'field': 'name_en',       'name': 'Наименование на английском',},
            {'field': 'short_name_1c',       'name': 'Краткое наименование 1с',},
            {'field': 'order_available_1s',       'name': 'Доступно для заказа в 1с',},
            {'field': 'no_datafiles',       'name': 'Не включать в выгрузки',},
            {'field': 'stock_available',       'name': 'Наличие на складах',},
            {'field': 'presentation_file',       'name': 'Презентация',},
            {'field': 'trainings',       'name': 'Тренинги',},
            {'field': 'price_combo',       'name': 'Цена розничная Combo',},
            {'field': 'price_combo_use',       'name': 'Ценообразование Combo',},
            {'field': 'price_kit_use',       'name': 'Ценообразование KIT-a',},
            {'field': 'accessory_for_preset',       'name': 'Является аксессуаром к пресету',},
            {'field': 'virtual_set',       'name': 'Виртуальный набор',},
            {'field': 'delivery_to_order',       'name': 'Поставка под заказ',},
            {'field': 'barcode',       'name': 'Штрихкоды',},
            {'field': 'project_position',       'name': 'Проектная позиция',},
            {'field': 'no_kp_image',       'name': 'Без изображения в КП',},
            {'field': 'price_wholesale',       'name': 'Цена опт',},
            {'field': 'price_large_wholesale',       'name': 'Цена крупный опт',},
            {'field': 'qty_wholesale',       'name': 'Кратность опт',},
            {'field': 'qty_large_wholesale',       'name': 'Кратность крупный опт',},
            {'field': 'spareparts',             'name': 'Запчасти',},
            {'field': 'sparepart_for',           'name': 'Является запчастью к',},
            {'field': 'site_category',           'name': 'Категории каталога на сайте',},
            {'field': 'newproduct',           'name': 'Новинка',},
            {'field': 'nds_use',           'name': 'Облагается НДС',},
            {'field': 'nds_size',           'name': 'Ставка НДС',},
            {'field': 'nds_price',           'name': 'Цена без НДС',},
        ]

        commit('set', {type: 'attributes', items:array})
        return
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
