import Vue  from 'vue'
import Urls from '@/../config/url.js'
import { Message, Loading } from 'element-ui';

const module_name = 'dealers'
const url = Urls.dealers
const images_url = Urls.dealers + 'images/'

const state = {
    list           : [],
    current        : {},
    data_loading   : false,
    total_count    : 0,
    edit_window    : false,
    options_list   : [],
    page_current   : 1,
    page_size      : 50,
    sort_field     : undefined,
    sort_direction : undefined,
    filters        : {},
    images_url     :  images_url,
    images_list    : [],
    images_loading : false,
    changelog_window : false,
    changelog_dealer: null,
}

var preprocessItem = function(item) {
    delete item.children
    return item
}

const actions = {
    get_paginated_list({commit, dispatch, rootState}) {
        let props = {
            url     : url,
            name    : module_name,
        }

        return dispatch('app/get_paginated_list', props, { root: true })
    },

    add_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/add_item', props, { root: true })
    },

    update_item({dispatch, commit, rootState}, item) {

        let props = {
            url         : url,
            name        : module_name,
            item        : item,
            preprocess  : preprocessItem,
        }

        return dispatch('app/update_item', props, { root: true })
    },

    delete_item({dispatch, commit, rootState}, data) {
        let props = {
            url  : url,
            name : module_name,
            data : data,
        }

        return dispatch('app/delete_item', props, { root: true })
    },

    get_options({commit, dispatch, rootState}) {
        let props = {
            url          : url,
            name         : module_name,
            option_title : 'site_name',
            add_fields   : ['parent_id','admin_only'],
        }

        return dispatch('app/get_options', props, { root: true })
    },

    get_images_list({dispatch, commit, rootState}, record_id){
        let props = {
            url     : images_url + record_id + '/',
            name    : module_name,
        }

        return dispatch('app/get_images_list', props, { root: true })
    },

    delete_image({dispatch, commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            Vue.axios.delete(url + '/images/' + data.image_id + '/' + data.dealer_id)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    update_image({dispatch, commit, rootState}, item){
        let props = {
            url         : images_url,
            name        : module_name,
            item        : item,
        }

        return dispatch('app/update_image', props, { root: true })
    },

    download_excel({dispatch, commit, rootState}) {
        let props = {
            url         : url,
            name        : module_name,
        }

        return dispatch('app/download_excel', props, { root: true })
    },

    get_dealer_full({dispatch, commit, rootState}, dealer_id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'full/' + dealer_id + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_moderation_list({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'moderation-list/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_new_list({dispatch, commit, rootState}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'new-list/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    save({dispatch, commit, rootState}, data){
        if ('expanded_services' in data.data) {
            data.data.expanded_services = data.data.expanded_services.filter(function (el) {
                return !el.del
            });
            data.data.expanded_services.forEach(item => {
                delete item["del"]
            })
        }
        if ('self_products' in data.data) {
            data.data.self_products = data.data.self_products.filter(function (el) {
                return !el.del
            });
            data.data.self_products.forEach(item => {
                delete item["del"]
            })
        }
        return Vue.axios.put(url + 'save/' + data.id + '/', data)
            .then((response) => {
                if (response.data.error_inn && response.data.error_inn.length)
                    Message({
                        message: `Данные не были сохранены. Указан ИНН, используемый в данных другой компании: ${response.data.error_inn.join(',')}`,
                        type: 'error'
                    });
                else if (response.data.changes)
                    Message({
                        message: 'Данные партнера сохранены в карточку',
                        type: 'success'
                    });
                else
                    Message({
                        message: 'Изменений не было',
                        type: 'success'
                    });
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
    },

    run_applywh({ commit, rootState, dispatch }){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'run-applywh/')
            .then((response) => {
                let body = response.data
                if (body.status && body.status === 'start') {
                    resolve(true);
                }
                else {
                    Message({
                        message: 'Процесс уже запущен на сервере. Пожалуйста, дождитесь окончания.',
                        type: 'error'
                    })
                    resolve(false);
                }
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_applywh_log({commit, dispatch}){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'get-applywh-log/')
            .then((response) => {
                let body = response.data
                let sip = body.stop ? false : true
                resolve({ run_in_progress: sip, content: body.content })
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    run_applyservices({ commit, rootState, dispatch }){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'run-apply-services/')
            .then((response) => {
                let body = response.data
                if (body.status && body.status === 'start') {
                    resolve(true);
                }
                else {
                    Message({
                        message: 'Процесс уже запущен на сервере. Пожалуйста, дождитесь окончания.',
                        type: 'error'
                    })
                    resolve(false);
                }
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_applyservices_log({commit, dispatch}){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'get-apply-services-log/')
            .then((response) => {
                let body = response.data
                let sip = body.stop ? false : true
                resolve({ run_in_progress: sip, content: body.content })
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    merge_dealers({commit, dispatch}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'merge/', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    save_comments({commit, dispatch}, data){
        return new Promise((resolve, reject) => {
            Vue.axios.post(url + 'comment/' + data.id, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_certificate({dispatch, commit, rootState}, data) {
        let loading_instanse = Loading.service({
            lock: true,
            text: 'Создается сертификат',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        return Vue.axios.post(url + 'certificate/' + data.dealer_id + '/' + data.unit_id + '/').then((response) => {
            let body = response.data
            loading_instanse.close()
            window.open(body);
        })
        .catch(error => {
            loading_instanse.close()
            dispatch('app/process_error', error, { root: true })
        })
    },

    get_changelog({dispatch, commit, rootState}, id){
        return new Promise((resolve, reject) => {
            Vue.axios.get(url + 'changelog/' + id + '/')
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    download_excel_mains({dispatch, commit, rootState}) {
        let loading_instanse = Loading.service({
            lock: true,
            text: 'Создается Excel файл',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        return Vue.axios.post(url + 'excel-mains-download/').then((response) => {
            let body = response.data
            loading_instanse.close()
            window.open(body);
        })
        .catch(error => {
            loading_instanse.close()
            dispatch('app/process_error', error, { root: true })
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
    set_filter(state, {type, items}) {
        state.filters[type].value = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
