import { host } from '@/../config/host.js'

const urls = {
    login                        : host + '/login/',
    checkToken                   : host + '/check-token/',
    refreshToken                 : host + '/refresh-token/',
    restorePasswordRequest       : host + '/restore-password-req/',

    tinymceUpload                : host + '/upload/tinymce/upload/',
    
    adminUsers                   : host + '/admin/users/',
    adminMenu                    : host + '/admin/menu/',
    adminRestUsers               : host + '/rest/users/',
    adminAttributesPim           : host + '/admin/pimattributes/',
    adminAttributesDealers       : host + '/admin/dealers-attributes/',
    adminAttributesProjects      : host + '/admin/projects-attributes/',
    adminFileManager             : host + '/admin/filemanager/',
    adminRestLog                 : host + '/rest/log/',
    adminCameUsers               : host + '/admin/cameusers/',
    adminAmoUsers                : host + '/amo/users/',
    adminAmoGetLog               : host + '/amo/get-log/',
    adminAmoSendLog              : host + '/amo/send-log/',

    settingsEmail                : host + '/settings/emails/',
    settingsSite                 : host + '/settings/site/',
    devConfig                    : host + '/settings/config/',

    seoSettings                  : host + '/seo/settings/',
    seoRedirect                  : host + '/seo/redirect/',
    seoCatalogTags               : host + '/catalog/tags/seo/',
    seoLandings                  : host + '/landings/seo/',
    seoPricelists                : host + '/pricelists/pricelist/seo/',
    seoProjects                  : host + '/projects/projects/seo/',
    seoAcademyCity               : host + '/academy/city/seo/',
    seoAcademyCourse             : host + '/academy/course/seo/',
    seoAcademyOnlineCourse       : host + '/academy/course-online/seo/',
    seoAcademyTrainers           : host + '/academy/trainers/seo/',
    seoArticles                  : host + '/articles/seo/',
    seoBlog                      : host + '/blog/seo/',
    seoCatalogCategory           : host + '/catalog/category/seo/',
    seoCatalogProduct            : host + '/catalog/product/seo/',
    seoDealerCity                : host + '/dealers/cities/seo/',
    seoNews                      : host + '/news/seo/',
    seoPages                     : host + '/pages/seo/',

    pages                        : host + '/pages/',
    lkPages                      : host + '/lk-pages/',
    tags                         : host + '/tags/',
    news                         : host + '/news/',
    articles                     : host + '/articles/',
    landings                     : host + '/landings/',
    blog                         : host + '/blog/',
    solutions                    : host + '/solutions/',
    banners                      : host + '/banners/',
    contacts                     : host + '/contacts/',
    templateTexts                : host + '/template_texts/',
    garantees                    : host + '/garantees/',
    aboutLaws                    : host + '/about/laws/',
    aboutBlocks                  : host + '/about/blocks/',
    faqCategory                  : host + '/faq/category/',
    faqQuestion                  : host + '/faq/question/',
    promoslidesSets              : host + '/promoslides/sets/',
    promoslidesItems             : host + '/promoslides/items/',
    promotions                   : host + '/promotions/',

    catalogProductParameters     : host + '/catalog/parameters/',
    catalogCategory              : host + '/catalog/category/',
    catalogCategoryFilters       : host + '/catalog/filters/',
    catalogSeries                : host + '/catalog/series/',
    catalogProduct               : host + '/catalog/product/',
    catalogProductComments       : host + '/catalog/product_comments/',
    catalogProductPresets        : host + '/catalog/product_presets/',
    catalogTags                  : host + '/catalog/tags/',
    catalogIcons                 : host + '/catalog/icons/',
    catalogPictograms            : host + '/catalog/pictograms/',
    catalogApplicationImages     : host + '/catalog/application_images/',
    catalogProductTexts          : host + '/catalog/texts/',
    catalogComponents            : host + '/catalog/components/',
    catalogComponentsParameters  : host + '/catalog/components-parameters/',
    catalogProductGarantee       : host + '/catalog/garantee/',
    catalogNewProductsLists      : host + '/catalog/newproducts/lists/',
    catalogNewProductsItems      : host + '/catalog/newproducts/items/',
    catalogMarketLaunch          : host + '/catalog/market-launch/',
    catalogWarehouse             : host + '/catalog/warehouse/',
    catalogSiteCategories        : host + '/catalog/site_category/',

    pricelistsDynamic            : host + '/pricelists/pricelist/',
    pricelistsDynamicBlocks      : host + '/pricelists/block/',
    pricelistsPasswords          : host + '/pricelists/passwords/',

    projects                     : host + '/projects/projects/',
    projectsZones                : host + '/projects/zones/',
    projectsMarkets              : host + '/projects/markets/',
    projectsTypes                : host + '/projects/types/',

    supportProductPassports      : host + '/support/product/passports/',
    supportProductInstructions   : host + '/support/product/instructions/',
    supportProductCertificates   : host + '/support/product/certificates/',
    supportProductCatalogs       : host + '/support/product/catalogs/',
    supportProductProjectdocs    : host + '/support/product/projectdocs/',
    supportProductPresentations  : host + '/support/product/presentations/',
    supportProductVideo          : host + '/support/product/video/',

    supportCompanyCertificates   : host + '/support/other/company_certificates/',
    supportNoProductCertificates : host + '/support/other/noproduct_certificates/',
    supportSiteFiles             : host + '/support/other/files/',
    supportPricelists            : host + '/support/other/pricelists/',

    academyImages                : host + '/academy/images/',
    academyCity                  : host + '/academy/city/',
    academyTrainers              : host + '/academy/trainers/',
    academyCategory              : host + '/academy/category/',
    academyCourse                : host + '/academy/course/',
    academyEvent                 : host + '/academy/events/',
    academyCertificate           : host + '/academy/certificates/',
    academyRegistrations         : host + '/academy/registrations/',
    academyQuestions             : host + '/academy/questions/',
    academyInformation           : host + '/academy/information/',
    academyCourseOnline          : host + '/academy/course-online/',
    academyIspringUsers          : host + '/academy/ispring-users/',

    shopUsers                    : host + '/shop/users/',
    shopOrders                   : host + '/shop/orders/',

    dealers                      : host + '/dealers/dealers/',
    dealersTypes                 : host + '/dealers/types/',
    dealersServices              : host + '/dealers/services/',
    dealersExpandedServices      : host + '/dealers/expaned_services/',
    dealersPaymentTypes          : host + '/dealers/payment_types/',
    dealersCities                : host + '/dealers/cities/',
    dealersDbCities              : host + '/dealers/db_cities/',
    dealersRegions               : host + '/dealers/regions/',
    dealersDirectorsRegions      : host + '/dealers/directors_regions/',
    dealersNew                   : host + '/dealers/dealers-new/',
    oxilorCities                 : host + '/dealers/oxilor-cities/',
    dealersAddReasons            : host + '/dealers/add-reasons/',

    feedbackSubscribe            : host + '/feedback/subscribe/',
    feedbackRequestCategory      : host + '/feedback/request_category/',
    feedbackRequest              : host + '/feedback/request/',
    feedbackProject              : host + '/feedback/project/',
    feedbackDealer               : host + '/feedback/dealer/',

    bptCategory                  : host + '/bpt/category/',
    bptVideo                     : host + '/bpt/video/',
    bptFiles                     : host + '/bpt/files/',

    kpParkingSettings : host + '/kp/parking/settings/',
    kpParking         : host + '/kp/parking/kp/',
    
    cameClubCities       : host + '/came-club/cities/',
    cameClubPlaces       : host + '/came-club/places/',
    cameClubTeams        : host + '/came-club/teams/',
    cameClubEvents       : host + '/came-club/events/',

    projectsSaleProjects : host + '/projects-sale/projects/',
    projectsSaleObjects  : host + '/projects-sale/objects/',

    reporting            : host + '/reporting/',
}

export default urls
